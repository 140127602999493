<template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <div class="row">
      <div v-for="item in items" class="col-md-3 mb-5">
        <app-card>
          <template #body>
            <div class="text-center mb-7">
              <router-link v-if="!hideLink" :to="`/${entity}/${item.id}`">
                <div class="font-size-h3 label label-xl h-auto label-outline-dark label-pill label-inline text-hover-info">{{ item[title] }}</div>
              </router-link>
              <div v-else class="font-size-h3">{{ item[title] }}</div>
            </div>
            <hr class="bg-secondary">
            <slot name="body" :item="item"></slot>
            <div class="mt-7">
             <slot name="actions" :item="item"></slot>
            </div>
          </template>
        </app-card>
      </div>
    </div>
    <app-pagination :meta="meta" @pageChange="page = $event"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppPagination from './components/AppPagination'

export default {
  components: {
    AppCard,
    AppPagination
  },
  props: {
    entity: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'name'
    },
    filters: {
      default: null
    },
    moduleName: {
      type: String,
      default: '',
    },
    requestUrl: {
      type: String,
      default: '',
    },
    hideLink: {
      type: Boolean,
      default: false
    },
    updateData: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    page: 1,
    isLoading: false
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      items: 'tableStore/ITEMS',
      meta: 'tableStore/META',
    })
  },
  watch: {
    page(val) {
      if (val) {
        this.getData();
      }
    },
    updateData(val) {
      if (val) {
        this.getData();
      }
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      await this.$store.dispatch('tableStore/GET_ITEMS', {
        requestUrl: this.requestUrl,
        filters: this.filters,
        pagination: {
          page: this.page,
          per_page: this.perPage
        }
      });
    },
    beforeDestroy() {
      this.$store.commit('tableStore/CLEAR_ITEMS');
    }
  }
}
</script>