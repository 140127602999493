<template>
  <div class="d-flex justify-content-between px-3">
    <b-col sm="7" md="6" class="my-1 px-0">
      <b-pagination
          v-model="meta.current_page"
          :total-rows="meta.total"
          :per-page="meta.per_page"
          align="fill"
          class="mb-5"
          @input="$emit('pageChange', $event)"
      ></b-pagination>
    </b-col>
    <div class="my-1 d-flex flex-row justify-content-center align-items-center">
      <span class="table-items-info mr-2">{{ computedMetaInfo }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    computedMetaInfo() {
      return this.meta.total
          ? `${this.$t('label.showing')} ${this.meta.from || 0}-${this.meta.to || 0} ${this.$t('label.of')} ${this.meta.total}`
          : this.$t('label.no_records_found');
    }
  }
}
</script>

<style lang="scss" scoped>
.table-items-info {
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
}
</style>